import React, { useContext } from "react"
import { ThemeContext } from "../theme-context"
import "./panel.scss"

function Projects({ title, subtitle, children }) {
  const { state: { style } } = useContext(ThemeContext)
  return (
    <div className='Panel'>
      <h2 className={style === 'dark' ? "text-white" : ""}>{title}</h2>
      <p className={`f4 mb-4 ${style === 'dark' ? 'text-white' : 'text-gray'}`}>{subtitle}</p>
      <div className="d-sm-flex flex-wrap gutter-condensed mb-4">
        {children}
      </div>
    </div>
  )
}

export default Projects
