import React, { useContext } from "react"

import SidebarLayout from '../components/sidebarLayout'
import { ThemeContext } from "../theme-context"
import MastHead from '../components/mastHead'
import Projects from '../components/projects'
import Interests from '../components/interests'
import About from '../components/about'
import Thoughts from '../components/thoughts'
import SEO from '../components/seo'
import useSiteMetadata from '../hooks/siteMetaData'

function IndexPage() {
  const { state: { style }, dispatch } = useContext(ThemeContext)
  const { layout } = useSiteMetadata()
  return <SidebarLayout>
    <About />
    <Thoughts />
  </SidebarLayout>
}

export default IndexPage
