import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import TopicCard from "./topicCard"
import Panel from './panel'
import { ThemeContext } from "../theme-context"

import './about.scss'

const scrollDown = () => {
  if (window.innerWidth >= 768) {
    return
  }
  setTimeout(() => {
    const item = document.getElementById('child-box')
      if (item && item.scrollIntoView) {
        item.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
  }, 150)
}

function Topics() {
  const { state: { style } } = useContext(ThemeContext)
  return (
    <Panel title='About' subtitle='Who I am'>
      <p className={`mb-3 f4 ${style === 'dark' ? 'text-white' : 'text-gray'} aboutText`}>
        Hey, I'm Nick. I enjoy solving problems with technology.
        I build websites, apps, internal tooling systems, business automation software, and much more.
        I have a Bachelor's degree in Computer Science from the University of Central Florida and spend
        most of my time working with tech startups.
        If you have an interesting project, <Link to='/contact' onClick={scrollDown}>get in touch</Link>!
      </p>
    </Panel>
  )
}

export default Topics
