import React, { useContext } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Panel from './panel'
import PostCard from "./postCard"
import { ThemeContext } from "../theme-context"

function Thoughts() {
  const { state: { style } } = useContext(ThemeContext)
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(limit: 6, filter: { frontmatter: { published: { eq: true } } }) {
          edges {
            node {
              frontmatter {
                title
                published
              }
              fields {
                slug
                postDate
              }
              html
            }
          }
        }
      }
    `
  )
  const { edges } = allMarkdownRemark || { edges: [] }
  return edges.length > 0 ?
    <Panel title='Thoughts' subtitle="Some things I've written">
      {edges.map((edge, index) => (
        <div key={index} className="col-sm-6 col-md-12 col-lg-6 col-xl-4 mb-3">
          <PostCard post={edge.node} />
        </div>
      ))}
    </Panel>
    : null
}

export default Thoughts
