import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import RepoCard from "./repoCard"
import { ThemeContext } from "../theme-context"
import Panel from './panel'
import Repos from '../data/repos.json'

function Projects() {
  const { state: { style } } = useContext(ThemeContext)
  // const { github: { viewer: { repositories } } } = useStaticQuery(
  //   graphql`
  //     query {
  //       github {
  //         viewer {
  //           repositories(privacy: PUBLIC, affiliations: OWNER, first: 60, orderBy: {field: CREATED_AT, direction: DESC}) {
  //             nodes {
  //               owner {
  //                 login
  //               }
  //               name
  //               url
  //               description
  //               stargazers {
  //                 totalCount
  //               }
  //               forkCount
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `
  // )
  return (
    <Panel title='Open source' subtitle='Some things I built'>
        {Repos.map((repository, i) => (
          <div key={i} className="col-sm-6 col-md-12 col-lg-6 col-xl-4 mb-3">
            <RepoCard repository={repository} />
          </div>
        ))}
    </Panel>
  )
}

export default Projects
