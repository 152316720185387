import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import TopicCard from "./topicCard"
import Panel from './panel'
import { ThemeContext } from "../theme-context"

function Topics() {
  const { state: { style } } = useContext(ThemeContext)
  const { allDataYaml: { edges } } = useStaticQuery(
    graphql`
      query {
        allDataYaml {
          edges {
            node {
              topics {
                name
                web_url
                image_url
                description
              }
            }
          }
        }
      }
    `
  )
  const topics = edges[0].node.topics
  return (
    <Panel title='Interests' subtitle='Some stuff that excites me'>
      {topics.map((topic, i) => (
        <div key={i} className="col-sm-6 col-md-12 col-lg-6 col-xl-4 mb-3">
          <TopicCard topic={topic} />
        </div>
      ))}
    </Panel>
  )
}

export default Topics
